/**  =====================
      Sticky Notes css start
==========================  **/
/* * Hide from both screenreaders and browsers: h5bp.com/u */

.hidden {
	display: none !important;
	visibility: hidden;
}
/* * Hide only visually, but have it available for screenreaders: h5bp.com/v */

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
/* * Extends the .visuallyhidden class to allow the element to be focusable * when navigated to via the keyboard: h5bp.com/p */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
}
/* * Hide visually and from screenreaders, but maintain layout */
.invisible {
	visibility: hidden;
}

.clearfix:after,
.clearfix:before {
	content: " ";
	/* 1 */
	display: table;
	/* 2 */
}

.clearfix:after {
	clear: both;
}

.noflick {
	perspective: 1000;
	backface-visibility: hidden;
	transform: translate3d(0,0,0);
}

::-webkit-input-placeholder {
	color: rgba(255,255,255,.7);
}

:placeholder {
	/* Firefox 18- */
	color: rgba(255,255,255,.7);
}


/* ==========================================================================
   Author's custom styles
   ========================================================================== */
#board {
	margin-top: 40px;
	overflow-y: visible;
	@extend .noflick;
}

.note {
	float: left;
	display: block;
	position: relative;
	padding: 1em;
	width: 260px;
	min-height: 280px;
	margin: 0 30px 30px 0;
	background: $theme-color;
	box-shadow: 7px 10px 20px 0 rgba(0,0,0,0.2);
	transform: rotate(2deg);
	transform: skew(-1deg,1deg);
	transition: transform 0.15s;
	z-index: 1;
	@extend .noflick;

	&:hover {
		cursor: move;
	}

	&.ui-draggable-dragging:nth-child(n) {
		box-shadow: 5px 5px 15px 0 rgba(0,0,0,.3);
		transform: scale(1.125) !important;
		z-index: 100;
		cursor: move;
		transition: transform 0.150s;
	}

	textarea {
		background-color: transparent;
		border: none;
		resize: vertical;
		font-family: "Gloria Hallelujah", cursive;
		width: 100%;
		color: #fff;
		padding: 5px;

		&:focus {
			outline: none;
			border: none;
			box-shadow: none;
		}

		&.title {
			font-size: 24px;
			line-height: 1.2;
			color: #fff;
			height: 64px;
			margin-top: 20px;
		}

		&.cnt {
			min-height: 200px;
		}
	}

	&:nth-child(3n+2) {
		background: $theme-color2;
	}

	&:nth-child(3n+3) {
		background: $primary-color;
	}
}
/* Button style  */

.button {
	color: #FFFFFF;
	border-radius: 3px;
	box-shadow: 1px 1px 3px rgba(0,0,0,.3),inset 0 -1px 2px -1px rgba(0,0,0,.5), inset 0 1px 2px 1px rgba(255,255,255,.3);
	text-shadow: 0 -1px 0 rgba(0,0,0,.3), 0 1px 0 rgba(255,255,255,.3);
	text-decoration: none;
	transition: transform 0.150s, background 0.01s;
	@extend .noflick;

	&:hover {
		box-shadow: 0 0 0 0 rgba(0,0,0,.3), inset 0 -1px 2px -1px rgba(0,0,0,.5), inset 0 1px 2px 1px rgba(255,255,255,.3);
	}

	&:active {
		text-shadow: 0 1px 0 rgba(0,0,0,.3), 0 -1px 0 rgba(255,255,255,.3);
		box-shadow: inset 0 1px 2px rgba(0,0,0,.3), inset 0 -1px 2px rgba(255,255,255,.3);
		outline: none;
	}

	&.remove {
		position: absolute;
		top: 5px;
		right: 5px;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background-color: $danger-color;
		text-align: center;
		line-height: 16px;
		padding: 7px;
		border-color: darken($danger-color,5%);
		font-weight: bolder;

		&:hover {
			color: #fff;
			background-color: darken($danger-color,2%);
		}
	}
}

.author {
	position: absolute;
	top: 20px;
	left: 20px;
}
/**====== Sticky Notes css end ======**/
