pell-editor > div {
  border: 1px solid #333;
}

pell-editor .pell-button {
  background-color: transparent;
  height: 40px !important;
  width: auto !important;
  padding-left: 10px;
  padding-right: 10px;
}
