
.bpDocumentView {
  width: 40%;
  //background-color: rgba(51, 170, 51, .4);
}

.bpDocumentView modal-content {
}

.bpParentModal {
  //background-color: rgba(171, 51, 51, .4);
}

.bpParentModal modal-content {
}

a {
  text-decoration: none;
}

body {
  line-height: 1.5;

  td, th {
    font-family: "Open Sans", sans-serif;
  }
}
header {
  width: 100%;
}

.form-control {
  height: auto;
}

select.form-control {
  line-height: 30px;
  height: 30px;
}

.border-less-image-gallery ks-modal-gallery .plain-container .image {
  border: none;
}

.datta-accordion {
  .card-header{
    .btn{
      padding: 0;
    }
  }
}

.h-back-user-list {
  color: $primary-color !important;
}

@media only screen and (max-width: 575px) {
  .pcoded-header .dropdown .dropdown-menu {
    left: 0 !important;
  }

  wizard.vertical .vertical a {
    display: none;
  }
}

@media only screen and (max-width: 360px) {
  .filter-bar .card-task .task-board {
    float: none;
    margin-top: 25px;
  }
}

.pcoded-header .dropdown.show:before {
  left: -10px !important;
}

div.horizontal-scroll {
  overflow: auto;
  white-space: nowrap;
}

#timer {
  color: #ffffff;
  b {
    font-size: 24px;
  }
}

pre code{
  width: auto;
  min-width: 100%;
  margin-left: -80px !important;
}


.btn.btn-sm {
  padding: 1px 5px;
  border-radius: .15rem;
}

.btn.btn-lg {
  padding: 12px 25px;
  border-radius: .55rem;
}


/*.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a{
  position: relative;
  &:after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 5;
  }
}*/

/** CUSOMT BudgetPlanner STYLE **/

.bpRight {
  text-align: right;
}

.bpMiddle {
  text-align: center;
}

.bpTable {
  width: 100%;
}

.bpTable tr th {
  font-size: 12px;
  white-space: nowrap;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}

.bpTable tr td {
  font-size: 12px;
  white-space: nowrap;
}

.bpActionButton {
  width: 50px;
}

.dropdown-menu {
  font-size: 12px;
}

.bpAuditInfo {
  font-size: 10px;
}


.subTotalTable {

}

.subTotalTable input {
  width: 100px;
  text-align: right;
}

.subTotalTable td {
  padding: 5px;
}

.bpDelete {
  color: #dc3545;
}

.bpEdit {
  color: #fd7e14;
}

.bpDocument {
  color: #17a2b8;
}

.bpEditDisabled {
  color: gray;
}

.bpDocumentDisabled {
  color: gray;
}

.bpDeleteDisabled {
  color: gray;
}

.bpViewDocument {
  color: #17a2b8;
}

.bpAddNew {
  color: #1e6abc;
}

.bpAddNewDisabled {
  color: gray;
}

.bpFiscalPeriod {
  width: 50px;
}

.bpVendor {
  width:200px;
}

.bpDate {
  width: 100px;
}

.bpAmount {
  width: 100px;
}

.bpStatus {
  width: 50px;
}

.bpProjectName {
  width: 250px;
}

.bpCustomerName {
  width: 250px;
}
/** HERE **/
.bpDataTable {
  height: 200px;
}

/** HERE **/

.bpTitleInput {
  border: 0px;
}

table.dataTable,
table.dataTable th,
table.dataTable td {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.bpStripeInput {
  font-size: 15px;
  padding: 8px 8px 8px 8px;
  background-color: transparent;
}

.bpStripeInput-error {
  border-color: #e01b41;
  border-width: 2px;
}

.bpvalidation-error {
  color: red;
}
