table.dataTable tbody th, table.dataTable tbody td {
  padding: 1.05rem .75rem;
}
.dataTables_length,
.dataTables_filter{
  label{
    display: flex;
    align-items: center;
    select,
    input{
      background: $theme-background;
      padding: 10px 20px;
      border: 1px solid #ced4da;
      margin: 0 10px;
      border-radius: .2rem;

      &:focus {
        box-shadow: none;
        outline: none;
        background: $theme-background;
      }

      &::-moz-placeholder {
        color: #aaaeb3;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: #aaaeb3;
      }

      &::-webkit-input-placeholder {
        color: #aaaeb3;
      }
    }
  }
}

.dataTables_wrapper {
  .dt-buttons{
    display: inline-flex;
    vertical-align: middle;
  }
  .dt-button{
    background: $secondary-color;
    border:1px solid $secondary-color;
    color: #fff;
    font-size: 1rem;
    padding: 10px 20px;
    border-radius: 0;
    transition: all 0.3s ease-in-out;
    &:first-child{
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
    &:last-child{
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
    +.dt-button{
      margin-left: -1px;
    }
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active {
      background: $secondary-color;
      color: #fff;
      box-shadow: none;
    }

    &.active {
      background: $secondary-color;
      color: #fff;
      border:1px solid $secondary-color;
      box-shadow: none;
    }
  }
}

table.dataTable tbody > tr.selected, table.dataTable tbody > tr > .selected {
  background-color: #dceff7;
}
